import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as queryString from 'query-string';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { List2Res, Metrics, MetricUpsertRequest, Pageable, PageableModel } from 'src/app/shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MetricService {

  constructor(
    private http: HttpClient,
  ) { }

  search(pageable?: PageableModel): Observable<List2Res<Metrics>> {
    const params = queryString.stringify({ ...pageable });

    return this.http.get<List2Res<Metrics>>(`${environment.apiUrl}/metrics?${params}`);
  }

  create(payload: MetricUpsertRequest) {
    return this.http.post<Metrics>(`${environment.apiUrl}/metrics`, payload);
  }

  update(id: number, payload: MetricUpsertRequest) {
    return this.http.put<Metrics>(`${environment.apiUrl}/metrics/${id}`, payload);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/metrics/${id}`);
  }
}
